import LogoImage from '../../assets/logo.svg';
import './styles.scss';

const Logo = () => (
  <a className="logo-wrapper" href="https://www.discover-airlines.com">
    <img className="logo" src={LogoImage} alt="Discover Airlines logo" />
  </a>
);

export default Logo;
