export const iconOverrides = {
    Person: (className: string) => (
        <svg
            className={className}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_433_403)">
                <path
                    d="M15.4824 12.9412H8.51765C7.30319 12.9919 6.15526 13.5097 5.31348 14.3866C4.47169 15.2634 4.00113 16.4315 4 17.6471V21.4118H20V17.6471C19.9989 16.4315 19.5283 15.2634 18.6865 14.3866C17.8447 13.5097 16.6968 12.9919 15.4824 12.9412Z"

                />
                <path
                    d="M12 11.5294C14.0792 11.5294 15.7647 9.8439 15.7647 7.76471C15.7647 5.68552 14.0792 4 12 4C9.92079 4 8.23528 5.68552 8.23528 7.76471C8.23528 9.8439 9.92079 11.5294 12 11.5294Z"

                />
            </g>
            <defs>
                <clipPath id="clip0_433_403">
                    <rect width="24" height="24" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    ),
    Info: (className: string) => (
        <svg
            className={className}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12 22C13.9778 22 15.9112 21.4135 17.5557 20.3147C19.2002 19.2159 20.4819 17.6541 21.2388 15.8268C21.9957 13.9996 22.1937 11.9889 21.8078 10.0491C21.422 8.10929 20.4696 6.32746 19.0711 4.92894C17.6725 3.53041 15.8907 2.578 13.9509 2.19215C12.0111 1.8063 10.0004 2.00433 8.17316 2.76121C6.3459 3.51809 4.78412 4.79981 3.6853 6.4443C2.58649 8.08879 2 10.0222 2 12C2 14.6522 3.05357 17.1957 4.92893 19.0711C6.8043 20.9464 9.34783 22 12 22ZM13.5385 17.8974H10.4615V10.718H13.5385V17.8974ZM12 5.84616C12.3296 5.84616 12.6519 5.94391 12.9259 6.12704C13.2 6.31018 13.4137 6.57048 13.5398 6.87502C13.6659 7.17956 13.6989 7.51467 13.6346 7.83797C13.5703 8.16128 13.4116 8.45825 13.1785 8.69134C12.9454 8.92442 12.6484 9.08316 12.3251 9.14747C12.0018 9.21177 11.6667 9.17877 11.3622 9.05262C11.0576 8.92648 10.7974 8.71286 10.6142 8.43877C10.4311 8.16469 10.3333 7.84246 10.3333 7.51282C10.3333 7.29395 10.3764 7.07723 10.4602 6.87502C10.544 6.67281 10.6667 6.48908 10.8215 6.33431C10.9763 6.17955 11.16 6.05678 11.3622 5.97302C11.5644 5.88927 11.7811 5.84616 12 5.84616Z"

            />
        </svg>
    ),
    Receipt: (className: string) => (
        <svg
            className={className}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_433_416)">
                <path
                    d="M19 4.63311H16.5421L14.8877 7.66645H9.11273L7.4584 4.63311H5V21.4331H19V4.63311ZM12.7 16.0664H7.1V15.1331H12.7V16.0664ZM16.9 13.7331H7.1V12.7998H16.9V13.7331ZM16.9 11.3998H7.1V10.4664H16.9V11.3998Z"

                />
                <path
                    d="M14.3333 6.73311L15.7333 4.16644H13.379C13.3241 3.84036 13.1555 3.54426 12.9031 3.33072C12.6506 3.11717 12.3306 3 12 3C11.6693 3 11.3494 3.11717 11.0969 3.33072C10.8444 3.54426 10.6758 3.84036 10.621 4.16644H8.26665L9.66665 6.73311H14.3333Z"

                />
            </g>
            <defs>
                <clipPath id="clip0_433_416">
                    <rect width="24" height="24" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    ),
    Tag: (className: string) => (
        <svg
            className={className}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12.1719 3L2.58594 12.5859C2.20794 12.9639 2 13.466 2 14C2 14.534 2.20794 15.0361 2.58594 15.4141L8.58594 21.4141C8.96394 21.7921 9.466 22 10 22C10.534 22 11.0361 21.7921 11.4141 21.4141L21 11.8281V3H12.1719ZM13 5H19V11L10 20L4 14L13 5ZM16 7C15.7348 7 15.4804 7.10536 15.2929 7.29289C15.1054 7.48043 15 7.73478 15 8C15 8.26522 15.1054 8.51957 15.2929 8.70711C15.4804 8.89464 15.7348 9 16 9C16.2652 9 16.5196 8.89464 16.7071 8.70711C16.8946 8.51957 17 8.26522 17 8C17 7.73478 16.8946 7.48043 16.7071 7.29289C16.5196 7.10536 16.2652 7 16 7Z"/>
        </svg>
    ),
    Pin: (className: string) => (
        <svg
            className={className}
            width="12"
            height="18"
            viewBox="0 0 12 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12.0002 5.98643C11.9959 2.67691 9.31035 -0.00372026 6.00008 3.87569e-06H5.98717C2.67667 0.00372801 -0.00347248 2.68982 3.37705e-06 6.00009C3.37705e-06 10.6126 6.00008 18 6.00008 18C6.00008 18 12.0002 10.6126 12.0002 6.00009V5.98643ZM6.00008 10.6493C3.46345 10.6493 1.40698 8.59283 1.40698 6.0562C1.40698 3.51956 3.46345 1.46309 6.00008 1.46309C8.53473 1.46856 10.588 3.52155 10.5932 6.0562C10.5932 8.59283 8.53697 10.6493 6.00008 10.6493Z"

            />
        </svg>
    ),
    Calendar: (className: string) => (
        <svg
            className={className}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_438_132)">
                <path
                    d="M21 3.81814H18.5456V5.45442H15.2727V3.81814H8.72729V5.45442H5.45442V3.81814H3V21.8185H21V3.81783V3.81814ZM4.63628 20.1819V8.72729H19.3637V20.1819H4.63659H4.63628Z"

                />
                <path
                    d="M6.27287 3H7.90915V4.63628H6.27287V3ZM16.0909 3H17.7274V4.63628H16.0909V3ZM6.27287 16.9093H7.90915V18.5456H6.27287V16.9093ZM6.27287 13.6364H7.90915V15.2727H6.27287V13.6364ZM6.27287 10.3636H7.90915V12.0002H6.27287V10.3636ZM9.54543 16.9093H11.182V18.5456H9.54543V16.9093ZM16.0912 15.2727V16.9093H14.4546V15.2727H16.0909H16.0912ZM17.7274 13.6364H12.8183V18.5456H17.7271V13.6364H17.7274ZM9.54543 13.6364H11.182V15.2727H9.54543V13.6364ZM9.54543 10.3636H11.182V12.0002H9.54543V10.3636ZM12.8183 10.3636H14.4546V12.0002H12.8183V10.3636ZM16.0909 10.3636H17.7274V12.0002H16.0909V10.3636Z"

                />
            </g>
            <defs>
                <clipPath id="clip0_438_132">
                    <rect width="24" height="24" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    ),
    ReceiptLight: (className: string) => (
        <svg
            className={className}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_433_416)">
                <path
                    d="M19 4.63311H16.5421L14.8877 7.66645H9.11273L7.4584 4.63311H5V21.4331H19V4.63311ZM12.7 16.0664H7.1V15.1331H12.7V16.0664ZM16.9 13.7331H7.1V12.7998H16.9V13.7331ZM16.9 11.3998H7.1V10.4664H16.9V11.3998Z"

                />
                <path
                    d="M14.3333 6.73311L15.7333 4.16644H13.379C13.3241 3.84036 13.1555 3.54426 12.9031 3.33072C12.6506 3.11717 12.3306 3 12 3C11.6693 3 11.3494 3.11717 11.0969 3.33072C10.8444 3.54426 10.6758 3.84036 10.621 4.16644H8.26665L9.66665 6.73311H14.3333Z"

                />
            </g>
            <defs>
                <clipPath id="clip0_433_416">
                    <rect width="24" height="24" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    ),
    Restrooms: (className: string) => (
        <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path d="M11.756 3H12.7317V21.5367H11.756V3Z" fill="#1E73AF"/>
            <path
                d="M19.0733 6.92168C19.8815 6.92168 20.5367 6.26652 20.5367 5.45834C20.5367 4.65017 19.8815 3.99501 19.0733 3.99501C18.2652 3.99501 17.61 4.65017 17.61 5.45834C17.61 6.26652 18.2652 6.92168 19.0733 6.92168Z"
                fill="#1E73AF"/>
            <path
                d="M20.0487 7.38998H18.0977C17.5801 7.38998 17.0838 7.59557 16.7179 7.96152C16.3519 8.32746 16.1463 8.82379 16.1463 9.34132V13.7327C16.1463 14.0017 16.3643 14.2193 16.633 14.2193H17.6097V20.0733C17.6024 20.2599 17.6695 20.4417 17.7962 20.5789C17.9229 20.716 18.0988 20.7974 18.2853 20.805H19.861C20.0476 20.7974 20.2235 20.716 20.3501 20.5789C20.4768 20.4417 20.5439 20.2599 20.5367 20.0733V14.2197H21.5133C21.5773 14.2197 21.6406 14.2071 21.6996 14.1826C21.7587 14.1581 21.8124 14.1222 21.8576 14.077C21.9028 14.0318 21.9386 13.9781 21.9631 13.919C21.9875 13.8599 22 13.7966 22 13.7327V9.34132C21.9999 8.82385 21.7943 8.32761 21.4284 7.96173C21.0624 7.59586 20.5661 7.39032 20.0487 7.39032V7.38998ZM7.85367 11.834V9.58565C7.85367 9.06813 7.64808 8.5718 7.28213 8.20585C6.91619 7.8399 6.41986 7.63432 5.90233 7.63432H4.92667C4.4092 7.63441 3.91296 7.84003 3.54708 8.20597C3.18121 8.5719 2.97567 9.06818 2.97567 9.58565V11.9823L2 15.269C2.68555 15.6091 3.43144 15.8108 4.195 15.8623V20.069C4.195 20.4753 4.525 20.805 4.93133 20.805H5.898C6.30467 20.805 6.634 20.475 6.634 20.0687V15.8623C7.39767 15.811 8.144 15.609 8.82933 15.269L7.85333 11.8337L7.85367 11.834Z"
                fill="#1E73AF"/>
            <path
                d="M5.41466 7.14633C6.22284 7.14633 6.878 6.49118 6.878 5.683C6.878 4.87482 6.22284 4.21967 5.41466 4.21967C4.60649 4.21967 3.95133 4.87482 3.95133 5.683C3.95133 6.49118 4.60649 7.14633 5.41466 7.14633Z"
                fill="#1E73AF"/>
        </svg>

    ),
    Wheelchair: (className: string) => (
        <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.55 14.8849C19.48 14.5421 19.2937 14.2341 19.0226 14.0129C18.7516 13.7917 18.4124 13.6709 18.0626 13.671H13.5193L13.2839 12.4103H16.3508C16.6188 12.4162 16.8783 12.3154 17.0721 12.1301C17.2659 11.9447 17.3781 11.69 17.384 11.4219C17.39 11.1538 17.2892 10.8943 17.1039 10.7006C16.9185 10.5068 16.6638 10.3946 16.3957 10.3886H12.9067L12.4638 8.0129C12.3711 7.5156 12.0848 7.07545 11.6676 6.7893C11.2505 6.50314 10.7367 6.39442 10.2394 6.48704C9.99319 6.5329 9.7584 6.62682 9.54845 6.76342C9.3385 6.90002 9.15752 7.07664 9.01583 7.28319C8.72968 7.70033 8.62095 8.21406 8.71357 8.71137L9.81174 14.6002C9.96028 15.4043 10.6085 16.0458 11.4186 16.1557C11.5249 16.1715 17.5089 16.1557 17.5089 16.1557L18.7127 21.1704C18.7725 21.4296 18.932 21.6548 19.1566 21.7973C19.3811 21.9398 19.6528 21.9882 19.9128 21.932C20.1727 21.8757 20.4001 21.7194 20.5457 21.4968C20.6913 21.2742 20.7434 21.0033 20.6908 20.7426L19.55 14.8849Z" fill="#1E73AF"/>
            <path d="M10.0964 5.7911C11.1433 5.7911 11.992 4.94243 11.992 3.89555C11.992 2.84867 11.1433 2 10.0964 2C9.04953 2 8.20087 2.84867 8.20087 3.89555C8.20087 4.94243 9.04953 5.7911 10.0964 5.7911Z" fill="#1E73AF"/>
            <path d="M10.0186 20.4834C7.53626 20.4834 5.5163 18.4634 5.5163 15.9811C5.5163 14.0195 6.77911 12.3513 8.53315 11.7356L8.25182 10.2267C5.79261 10.9842 4 13.2771 4 15.9811C4 19.3 6.69998 22 10.0189 22C13.0194 22 15.5135 19.7936 15.9645 16.9179L14.4213 16.9203C13.9878 18.9537 12.1794 20.4837 10.0186 20.4837V20.4834Z" fill="#1E73AF"/>
        </svg>
    ),
    SmokeArea: (className: string) => (
        <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22 12.532H4.55333V15.0853H22V12.532ZM17.1067 14.4467H5.191V13.17H17.1067V14.4467ZM3.27667 12.532H3.915V15.0853H3.27667V12.532ZM2 12.532H2.63833V15.0853H2V12.532ZM4.766 9.766H6.04267C7.56767 9.766 8.80867 8.52533 8.80867 7H8.17C8.16938 7.5641 7.94502 8.10492 7.54614 8.50381C7.14726 8.90269 6.60644 9.12705 6.04233 9.12767H4.76567C3.24067 9.12767 2 10.3683 2 11.8937H2.63833C2.63895 11.3296 2.86331 10.7887 3.26219 10.3899C3.66108 9.99098 4.2019 9.76662 4.766 9.766Z" fill="#1E73AF"/>
            <path d="M4.87232 10.9364V10.298C4.44932 10.2985 4.04376 10.4667 3.74462 10.7657C3.44548 11.0648 3.27719 11.4703 3.27666 11.8934H3.91499C3.91526 11.6395 4.0162 11.3962 4.19568 11.2167C4.37516 11.0372 4.6185 10.9363 4.87232 10.936V10.9364Z" fill="#1E73AF"/>
        </svg>
    ),
    Lounge: (className: string) => (
        <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_426_401)">
                <path d="M14.9464 18.9924V13.136C14.9453 12.7636 14.7968 12.4067 14.5335 12.1434C14.2701 11.88 13.9133 11.7316 13.5409 11.7305H7.12832L6.60358 9.06392C6.52783 8.685 6.30523 8.35145 5.9844 8.13608C5.66356 7.92071 5.27055 7.84103 4.89117 7.91442L4.17621 8.05498C3.79724 8.13062 3.4636 8.35319 3.24821 8.67406C3.03283 8.99492 2.95319 9.388 3.02672 9.76739L4.68877 18.9924C4.89374 19.9002 5.32126 20.398 6.09431 20.398H13.5409C13.9133 20.3968 14.2701 20.2484 14.5335 19.9851C14.7968 19.7217 14.9453 19.3648 14.9464 18.9924Z" fill="#1E73AF"/>
                <path d="M7.83813 7.88561L7.14988 8.02757C7.07561 8.04351 7.00275 8.06545 6.93202 8.09316C7.11071 8.34254 7.23388 8.62731 7.29325 8.92829L7.70647 11.0277H9.89677L9.49854 8.97795C9.42317 8.61294 9.2059 8.2928 8.89452 8.08796C8.58315 7.88311 8.20316 7.81033 7.83813 7.88561ZM20.7682 16.2821L16.8378 11.8628C16.4606 11.4119 16.1495 11.262 15.6518 11.262H14.517C14.8604 11.4395 15.1482 11.7084 15.3484 12.0391C15.5486 12.3698 15.6536 12.7494 15.6518 13.136V19.4609C15.6518 19.7094 15.7505 19.9478 15.9262 20.1235C16.1019 20.2992 16.3403 20.398 16.5888 20.398C16.8373 20.398 17.0756 20.2992 17.2514 20.1235C17.4271 19.9478 17.5258 19.7094 17.5258 19.4609V15.3333L19.3326 17.4861C19.4107 17.5833 19.5075 17.664 19.6172 17.7233C19.7269 17.7826 19.8473 17.8195 19.9714 17.8317C20.0955 17.8439 20.2208 17.8312 20.3399 17.7943C20.459 17.7575 20.5696 17.6972 20.6652 17.6171C20.7607 17.5369 20.8393 17.4385 20.8963 17.3276C20.9533 17.2167 20.9876 17.0955 20.9972 16.9712C21.0068 16.8469 20.9915 16.7219 20.9522 16.6035C20.9128 16.4852 20.8503 16.3759 20.7682 16.2821Z" fill="#1E73AF"/>
                <path d="M7.05993 7.27959C7.96557 7.27959 8.69973 6.54543 8.69973 5.6398C8.69973 4.73416 7.96557 4 7.05993 4C6.1543 4 5.42014 4.73416 5.42014 5.6398C5.42014 6.54543 6.1543 7.27959 7.05993 7.27959Z" fill="#1E73AF"/>
            </g>
            <defs>
                <clipPath id="clip0_426_401">
                    <rect width="24" height="24" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    ),
};
